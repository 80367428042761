<template>
  <div id="app">
    <p style="font-family: QXyingbixing;opacity: 0;height: 0;margin: 0;">字体预加载</p>
    <p style="font-family: Vijaya;opacity: 0;height: 0;margin: 0;">字体预加载</p>

    <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no"></meta>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {
    
  }
}
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top: 30px;
  box-sizing: border-box;
  min-height: 100vh;
  padding-bottom: 60px;
}

.flex{
  display: flex;
}

.mr-1{
  margin-right: 1rem;
}
</style>
