<template>
  <div class="home">
    <van-image class="moon-image" width="94vw" height="68vw" fit="cover" :src="require('../assets/images/type1-d.png')" />
    <van-cell-group class="input-box" inset>
      <van-field class="input" input-align="center" v-model="top_text" placeholder="上方文字，不写默认空白" />
      <van-field class="input" input-align="center" readonly v-model="date1" placeholder="----年--月--日"
        @click="datePickerF" />
      <van-popup v-model="show" position="bottom">
        <van-datetime-picker v-model="currentDate" type="date" title="选择日期" :formatter="dateFormatter"
          @cancel="show = false" @confirm="confirmPicker" :min-date="minDate" :max-date="maxDate" />
      </van-popup>
      <van-field class="input" input-align="center" v-model="bottom_text1" placeholder="下方文字，不写默认空白" />
      <!-- <van-field class="input" input-align="center" v-model="bottom_text2" placeholder="下方文字，不写默认空白" /> -->
    </van-cell-group>
    <div class="fixed-table">
      <van-button round block color="linear-gradient(to left, #ff6034, #ee0a24)" @click="submit">
        下一步，浏览效果图
      </van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
// @ is an alias to /src
export default {
  name: 'type1',
  components: {

  },
  data() {
    return {
      show: false,
      top_text: '',
      date1: '',
      bottom_text1: '',
      bottom_text2: '',
      currentDate: '',
      minDate: new Date(1949, 9, 1),
      maxDate: new Date(),
    }
  },
  created(){
    this.top_text = localStorage.getItem("top_text");
    this.date1 = localStorage.getItem("date1");
    this.bottom_text1 = localStorage.getItem("bottom_text1");
    // this.bottom_text2 = localStorage.getItem("bottom_text2");
  }, 
  methods: {
    // 选项格式化函数
    dateFormatter(type, value) {
      if (type === 'year') {
        return `${value}年`
      } else if (type === 'month') {
        return `${value}月`
      } else if (type === 'day') {
        return `${value}日`
      }
      return value
    },

    //开关日期选择器
    datePickerF() {
      this.show = !this.show;
    },

    //提交日期
    confirmPicker() {
      this.date1 = this.currentDate.pattern("yyyy年MM月dd日");
      this.datePickerF();
    },

    submit() {
      if (this.date1 == '') {
        Toast.fail('请选择月相日期！');
      } else{
        localStorage.setItem("top_text", this.top_text);
        localStorage.setItem("date1", this.date1);
        localStorage.setItem("bottom_text1", this.bottom_text1);
        // localStorage.setItem("bottom_text2", this.bottom_text2);
        this.$router.push('result');
      }
    }
  }
}
</script>

<style>
.moon-image{
  margin-bottom: 2rem;
}

.fixed-table {
  box-sizing: border-box;
  padding: 28px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.tips {
  font-size: 10px;
  margin-top: 24px;
  line-height: 24px;
}

.moon-check-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.moon-check-box>.moon-type {
  margin: 0 4vw;
  padding: 8px;
  border-radius: 6px;
}

.moon-check-box>.moon-type.checked {
  background-color: rgba(0, 0, 0, 0.34);
}

.moon-check-box>.moon-type>.moon-type-i {
  width: 34vw;
  height: 34vw;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.moon-check-box>.moon-type>.moon-type-i.moon-type1 {
  background-image: url("../assets/images/type1-i.png");
}

.moon-check-box>.moon-type>.moon-type-i.moon-type2 {
  background-image: url("../assets/images/type2-i.png");
}

.input-box {
  border-radius: 0 !important;
}

.input {
  font-size: 16px;
  border: 2px solid black;
  margin-bottom: 32px;
}

.title {
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 1px;
}

.moon-title {
  margin: 12px 0 6px;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
}
</style>
