import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Type1 from '../views/Type1.vue'
import Type2 from '../views/Type2.vue'
import Result from '../views/Result.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Index,
  },
  {
    path: '/type1',
    name: 'type1',
    component: Type1,
  },
  {
    path: '/type2',
    name: 'type2',
    component: Type2,
  },
  {
    path: '/result',
    name: 'result',
    component: Result,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
