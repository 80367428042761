<template>
  <div class="home">
    <van-cell-group class="input-box" inset>
      <van-field class="input" input-align="center" v-model="order" placeholder="请输入已付款订单编号" />
      <van-field class="input" input-align="center" type="tel" v-model="phone" placeholder="联系电话，如订单有问题会和您联系" />
    </van-cell-group>
    <p class="title">请选择您的定制模板</p>
    <div class="moon-check-box">
      <div :class="type == 1 ? 'moon-type checked' : 'moon-type'" @click="checkType(1)">
        <div class="moon-type-i moon-type1"></div>
        <p class="moon-title">单人月相</p>
      </div>
      <div :class="type == 2 ? 'moon-type checked' : 'moon-type'" @click="checkType(2)">
        <div class="moon-type-i moon-type2"></div>
        <p class="moon-title">双人月相</p>
      </div>
    </div>
    <p class="tips">提示：为避免影响您的制作进度<br />请准确选择和您订单对应的选项</p>
    <div class="fixed-table">
      <van-button round block color="linear-gradient(to left, #ff6034, #ee0a24)" @click="submit">
        确认信息，开始设计
      </van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
// @ is an alias to /src
export default {
  name: 'index',
  components: {

  },
  data() {
    return {
      type: 1,
      order: '',
      phone: '',
    }
  },
  created(){
    let type = localStorage.getItem("type");
    this.type = type?type:1;
    this.order = localStorage.getItem("order");
    this.phone = localStorage.getItem("phone");
  },  
  methods: {
    checkType(type) {
      this.type = type;
    },
    submit() {
      if (this.order == '') {
        Toast.fail('请输入订单编号！');
      } else if (this.phone == '') {
        Toast.fail('请输入联系电话！')
      } else if (this.phone.length != 11) {
        Toast.fail('请输入正确的联系电话！')
      }  else {
        localStorage.setItem("type", this.type);
        localStorage.setItem("order", this.order);
        localStorage.setItem("phone", this.phone);
        this.$router.push(this.type == 1 ? 'type1' : 'type2');
      }
    }
  }
}
</script>

<style>
.fixed-table {
  box-sizing: border-box;
  padding: 28px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.tips {
  font-size: 10px;
  margin-top: 24px;
  line-height: 24px;
}

.moon-check-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.moon-check-box>.moon-type {
  margin: 0 4vw;
  padding: 8px;
  border-radius: 6px;
}

.moon-check-box>.moon-type.checked {
  background-color: rgba(0, 0, 0, 0.34);
}

.moon-check-box>.moon-type>.moon-type-i {
  width: 34vw;
  height: 34vw;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.moon-check-box>.moon-type>.moon-type-i.moon-type1 {
  background-image: url("../assets/images/type1-i.png");
}

.moon-check-box>.moon-type>.moon-type-i.moon-type2 {
  background-image: url("../assets/images/type2-i.png");
}

.input-box {
  border-radius: 0 !important;
}

.input {
  font-size: 16px;
  border: 2px solid black;
  margin-bottom: 32px;
}

.title {
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 1px;
}

.moon-title {
  margin: 12px 0 6px;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
}
</style>
