<template>
  <div class="home">
    <div class="image-box">
      <div class="image-background result-bg">
        <van-image id="image-result" width="100%" height="auto" fit="cover" :src="result" />
      </div>
    </div>
    <van-cell-group class="input-box" style="margin-top:1rem;" inset>
      <van-field class="input" label="上方文字字体" input-align="center" readonly v-model="top_text_font"
        placeholder="请选择上方文字字体" @click="check_font('top_text_font')" />
      <!-- <van-field class="input" label="日期字体" input-align="center" readonly v-model="date_font" placeholder="请选择上方文字字体"
        @click="check_font('date_font')" /> -->
      <van-field class="input" label="下方文字字体" input-align="center" readonly v-model="bottom_text_font"
        placeholder="请选择下方文字字体" @click="check_font('bottom_text_font')" />
    </van-cell-group>
    <van-popup v-model="show" position="bottom">
      <van-picker classtitle="字体列表" show-toolbar :columns="font_list" @confirm="onConfirm" @cancel="onCancel" />
    </van-popup>
    <div class="fixed-table">
      <van-button round block color="linear-gradient(to left, #ff6034, #ee0a24)" @click="submit">
        确认无误，提交制作
      </van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { getLunar } from 'chinese-lunar-calendar'
import { Picker } from 'vant';

// @ is an alias to /src
export default {
  name: 'Result',
  components: {

  },
  data() {
    return {
      show: false,
      bg: require('../assets/images/border.png'),
      materialUrl: [require('../assets/images/moon/15.png'), require('../assets/images/moon/1.png')],
      result: '',
      type: 1,
      top_text: '',
      date1: '',
      date2: '',
      bottom_text1: '',
      bottom_text2: '',
      top_text_font: 'Vijaya',
      date_font: 'Vijaya',
      bottom_text_font: 'Vijaya',
      font_list: ['QXyingbixing', 'Vijaya'],
      check_font_type: '',
      font_size:0
    }
  },
  created() {
    var that = this;

    that.type = localStorage.getItem("type");
    that.top_text = localStorage.getItem("top_text");
    that.date1 = localStorage.getItem("date1");
    that.date2 = localStorage.getItem("date2");
    that.bottom_text1 = localStorage.getItem("bottom_text1");
    // that.bottom_text2 = localStorage.getItem("bottom_text2");

    that.materialUrl = [require('../assets/images/moon/' + that.dateToCnDate(that.date1).lunarDate + '.png'), that.type == 1 ? '' : require('../assets/images/moon/' + that.dateToCnDate(that.date2).lunarDate + '.png')]

    that.drawProdPicture();
  },
  methods: {
    onConfirm(value, index) {
      switch (this.check_font_type) {
        case 'top_text_font': this.top_text_font = value; break;
        case 'date_font': this.date_font = value; break;
        case 'bottom_text_font': this.bottom_text_font = value; break;
      }

      this.drawProdPicture();
      this.check_font_status();
    },
    onCancel() {
      this.check_font_status();
    },
    check_font_status() {
      if (this.show) {
        this.show = false;
        this.check_font_type = '';
      } else {
        this.show = true;
      }
    },
    check_font(check_font_type) {
      this.check_font_type = check_font_type;
      this.check_font_status();
    },
    dateToCnDate(date) {
      let d = [];

      d = date.split('年');
      let year = d[0];

      d = d[1].split('月');
      let month = d[0];

      d = d[1].split('日')
      let day = d[0];

      return getLunar(year, month, day);
    },
    drawProdPicture() {
      // 初始化画板
      let canvas = document.createElement("canvas")
      let context = canvas.getContext("2d")
      canvas.width = 888
      canvas.height = 888

      //创建背景图
      let bg = new Image()
      bg.src = this.bg
      var flag = true
      bg.setAttribute('crossOrigin', 'anonymous')

      // 将bg（背景图）加入画布
      bg.onload = () => {
        // context.drawImage(bg, 0, 0, 888, 888)

        if (flag) {
          flag = false
        } else {
          let src = canvas.toDataURL()
          this.result = src
        }
      }

      if (this.type == 1) {
        // 创建img1
        let img1 = new Image()
        img1.src = this.materialUrl[0]
        img1.setAttribute('crossOrigin', 'anonymous')
        // 将 img1 加入画布
        img1.onload = () => {
          context.drawImage(img1, 218, 218, 450, 450)
          if (flag) {
            flag = false
          } else {
            let src = canvas.toDataURL('image/png')
            this.result = src
          }
        }

        //写入文字
        context.font = "42px " + this.top_text_font;
        context.fillText(this.top_text, 80, 170);  // 文字的内容和位置

        context.textAlign = 'center';
        context.font = "42px " + this.bottom_text_font;
        context.fillText(this.bottom_text1, 444, 799);  // 文字的内容和位置

        context.font = "42px " + this.date_font;
        context.fillText(this.date1.replace('年', '.').replace('月', '.').replace('日', ''), 444, 735);  // 文字的内容和位置


      } else if (this.type == 2) {
        // 创建img1
        let img1 = new Image()
        img1.src = this.materialUrl[0]
        img1.setAttribute('crossOrigin', 'anonymous')
        // 将 img1 加入画布
        img1.onload = () => {
          context.drawImage(img1, 95, 291, 308, 308)
          if (flag) {
            flag = false
          } else {
            let src = canvas.toDataURL('image/png')
            this.result = src
          }
        }

        // 创建img2
        let img2 = new Image()
        img2.src = this.materialUrl[1]
        img2.setAttribute('crossOrigin', 'anonymous')
        // 将 img1 加入画布
        img2.onload = () => {
          context.drawImage(img2, 483, 291, 308, 308)
          if (flag) {
            flag = false
          } else {
            let src = canvas.toDataURL('image/png')
            this.result = src
          }
        }

        //写入文字
        context.font = "bold 42px " + this.top_text_font;
        context.fillText(this.top_text, 80, 170);  // 文字的内容和位置

        context.textAlign = 'center';
        context.font = "bold 42px " + this.bottom_text_font;
        context.fillText(this.bottom_text1, 444, 779);  // 文字的内容和位置
        // context.fillText(this.bottom_text2, 919, 1617);  // 文字的内容和位置


        context.font = "bold 42px " + this.date_font;
        context.fillText(this.date1.replace('年', '.').replace('月', '.').replace('日', ''), 249, 674);  // 文字的内容和位置
        context.fillText(this.date2.replace('年', '.').replace('月', '.').replace('日', ''), 637, 674);  // 文字的内容和位置
      }

      context.moveTo(1,1);
      context.lineTo(1,888);
      context.lineTo(888,888);
      context.lineTo(888,1);
      context.lineTo(1,1);
      
      context.moveTo(2,2);
      context.lineTo(2,887);
      context.lineTo(887,887);
      context.lineTo(887,2);
      context.lineTo(2,2);

      context.strokeStyle = 'black';
      context.stroke();
    },
    submit() {
      Toast.loading({
         duration: 0, // 持续展示 toast
         forbidClick: true,
         message: "正在提交..."
     });
      var that = this;
      that.$axios({
        method: "post",//指定请求方式
        url: "/api/order-submit",//请求接口（相对接口，后面会介绍到）
        data: {
          order: localStorage.getItem("order"),
          type: that.type,
          phone: localStorage.getItem("phone"),
          top_text: that.top_text,
          bottom_text1: that.bottom_text1,
          // bottom_text2: that.bottom_text2,
          date1: that.date1,
          date2: that.date2,
          image_base64: that.result
        }
      })
        .then(function (res) {
          Toast.clear();//清除加载效果
          Toast.success('提交成功！');

          localStorage.clear();
          setTimeout(function(){
            that.$router.push('/');
          },2000)
          
        })
    }
  }
}
</script>

<style>
#image-result{
  background-color:#fff;
  margin:0;
}

.image-box {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  margin-top: 4rem;
}

.image-background{
  padding:1rem;
  border: 1px solid #555;
}

.moon-image {
  margin-bottom: 2rem;
  height: 100vw;
  width: 100vw;
  background: rgba(0, 0, 0, 0.2);
}

.fixed-table {
  box-sizing: border-box;
  padding: 28px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.result-bg{
  background-image: url("../assets/images/border.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
